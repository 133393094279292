import Container from "@mui/material/Container";
import styled from "@mui/material/styles/styled";
import useLayout from "../context/useLayout";
import { secondarySideBarGap, secondarySideBarWidth } from "utils/constants";
import { Stack } from "@mui/material";
const space = secondarySideBarWidth + secondarySideBarGap;

export default function LayoutBodyWrapper({ children }) {
  const { openSecondarySideBar } = useLayout();

  const pathname = window.location.pathname;

  return (
    <RootBox compact={openSecondarySideBar}>
      <StyledContainer
        sx={{
          backgroundImage: `url(/images/page-bg.png)`,
          backgroundSize: 'cover',
          maxWidth: '444px'
        }}>
        {children}
      </StyledContainer>
    </RootBox>
  );
}

const RootBox = styled("div", {
  shouldForwardProp: (prop) => prop !== "compact",
})(({ theme, compact }) => ({
  background: "#2D2D2D",
  minHeight: "100vh",
  marginLeft: compact ? `${space}px` : "0px",
  transition: "margin-left 0.3s ease-in-out",
}));

const StyledContainer = styled(Stack)(({ theme }) => ({
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "top center",
  minHeight: "100vh",
  padding: "0px !important",
  position: "relative",
  margin: "auto"
}));
