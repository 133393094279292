import {
  Avatar,
  Box,
  Card,
  Grid,
  IconButton,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import AppTable from "components/AppTable";
import { FlexBetween, FlexRowAlign } from "components/flexbox";
import { t } from "i18next";
import CheckedIcon from "icons/CheckedIcon";
import CopyIcon from "icons/CopyIcon";
import ShareIcon from "icons/ShareIcon";
import TNXIcon from "icons/TNXIcon";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import useUserData from "../../hooks/useUserData";
import { BrownBox, TitleBox, TypographyOutline, WheatBox } from "./styled";
import { BinaryButton, BlackButton, SecondaryButton } from "../../page-sections/homepage/styled";
import Iconify from "../../components/Iconify";

export default function Referral() {
  const theme = useTheme();
  const handleCopy = (text) => {
    toast.success(t("Copied"));
  };

  const handleShare = async (url) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Cat Minner',
          text: 'Get your token now',
          url: url,
        });
        console.log('Share successful');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    };
  }
  const { userData } = useUserData();

  const quests = [
    {
      title: t("Each friend is invited"),
      bonuses: [
        {
          bonus: 3,
          symbol: "NOT",
          color: theme.palette.text.primary,
        },
        // {
        //   bonus: 1000,
        //   symbol: "CEN",
        //   color: theme.palette.text.primary,
        // },
      ],
      status: "PENDING",
    },
    {
      title: t("Each cat is bought by friends."),
      bonuses: [
        {
          bonus: 100,
          symbol: "NOT",
          color: theme.palette.text.primary,
        },
        // {
        //   bonus: 1000,
        //   symbol: "CEN",
        //   color: theme.palette.text.primary,
        // },
      ],
      status: "PENDING",
    },
    // {
    //   title: t("Each mission is completed by a friend"),
    //   bonuses: [
    //     {
    //       bonus: 20,
    //       symbol: "NOT",
    //       color: theme.palette.text.primary,
    //     },
    //     // {
    //     //   bonus: 1000,
    //     //   symbol: "CEN",
    //     //   color: theme.palette.text.primary,
    //     // },
    //   ],
    //   status: "PENDING",
    // },
  ];
  return (
    <RefContainer>
      <Stack gap={2} position={'relative'}>
        <TitleBox>
          <Stack alignItems={'center'} justifyContent={'center'} sx={{ position: 'absolute', top: 10, right: 0, left: 0, bottom: 0 }}>
            <Typography variant='h4' sx={{ color: "#fff", fontWeight: 600 }}>
              {t("Referral")}
            </Typography>
          </Stack>
          <img src="images/title-bg.png" style={{
            maxWidth: '444px',
            width: '100%',
            display: 'block',
            margin: 'auto'
          }} />
        </TitleBox>
        <Stack sx={{
          height: '75vh',
          backgroundSize: 'cover',
          marginTop: '0px',
          padding: '0px 1.5rem 0px 1rem',
          position: 'relative',
          zIndex: 1000
        }}>
          <BrownBox>
            <Typography variant="h5" textAlign={'center'} fontWeight={600}>Your Referral Link</Typography>
            <ReferralBox>
              <FlexBetween gap={1} sx={{ paddingRight: '1rem' }}>
                <Stack p={1} width={"100%"}>
                  <Typography sx={{ color: '#71360B' }}>
                    {t("Invite link")}
                  </Typography>
                  <Typography maxWidth={150} noWrap>
                    {`https://t.me/Toncentral_bot?${userData?.user?.refCode ? `start=${userData?.user?.refCode}` : ""}`}
                  </Typography>
                </Stack>
                <CopyToClipboard
                  text={`https://t.me/Toncentral_bot?${userData?.user?.refCode ? `start=${userData?.user?.refCode}` : ""}`}
                  onCopy={() => handleCopy()}>
                  <BinaryButton sx={{ minWidth: 35, height: 35, padding: 0 }}>
                    <Iconify icon="ic:round-content-copy" size={20} />
                  </BinaryButton>
                </CopyToClipboard>
                <SecondaryButton onClick={() => handleShare(`https://t.me/Toncentral_bot?${userData?.user?.refCode ? `start=${userData?.user?.refCode}` : ""}`)} sx={{ minWidth: 35, height: 35, padding: 0 }}>
                  <Iconify icon="mdi:share-all" size={20} />
                </SecondaryButton>
              </FlexBetween>
            </ReferralBox>
            <Typography sx={{ color: '#71360B' }}>
              {t(
                "The more friends you invite, the more rewards you earn. Earn bonuses when your friends start Upgrade or complete task.",
              )}
            </Typography>
          </BrownBox>
          <Grid container mt={2} columnSpacing={1.5}>
            <Grid item xs={6}>
              <Stack flexGrow={1} p={2} spacing={2} alignItems={'center'} sx={{ height: '100%', border: `2px solid #0082b7`, borderRadius: '1rem', background: '#FFF6E1' }}>
                <FlexRowAlign gap={1} >
                  <SecondaryButton sx={{ maxWidth: 30, minWidth: 30, height: 30, borderRadius: 30, padding: 0, boxShadow: '1px 1px 0px #CC4900 !important', }}>
                    <Box
                      component='img'
                      src={`/images/pepton-1.png`}
                      alt=''
                      sx={{
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </SecondaryButton>
                  <Typography variant="h5" fontWeight={700}>CEN</Typography>
                </FlexRowAlign>
                <Typography sx={{ color: '#71360B' }}>500 CEN/Invite</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack p={2} spacing={2} alignItems={'center'} sx={{ border: `2px solid #0082b7`, borderRadius: '1rem', background: '#FFF6E1', }}>
                <FlexRowAlign gap={1}>
                  <BlackButton sx={{ maxWidth: 30, minWidth: 30, height: 30, borderRadius: 30, padding: 0, boxShadow: 'none !important' }}>
                    <Box
                      component='img'
                      src={`/images/NOT-LOGO.png`}
                      alt=''
                      sx={{
                        width: '60%',
                        height: '60%',
                        marginTop: '2px'
                      }}
                    />
                  </BlackButton>
                  <Typography variant="h5" fontWeight={700}>NOT</Typography>
                </FlexRowAlign>
                <Typography textAlign={'center'} sx={{ color: '#71360B' }}>100/1000/10,000 NOT
                  Upgrade From invitee</Typography>
              </Stack>
            </Grid>
          </Grid>


          {/* <InvitedList /> */}
        </Stack>
        <Stack sx={{ position: 'absolute', bottom: 20, left: 0, width: '100%', top: 'auto' }}>
          <img src="images/paper-bg.png" style={{ marginBottom: '-20%' }} />
          <img src="images/paper-bg.png" />
        </Stack>
      </Stack>
    </RefContainer>
  );
}

const InvitedList = () => {
  const theme = useTheme();
  const data = [
    {
      id: 1,
      userName: "Namaste1",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 2,
      userName: "Namaste2",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 3,
      userName: "Namaste3",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 4,
      userName: "Namaste4",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 5,
      userName: "Namaste5",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 6,
      userName: "Namaste6",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 7,
      userName: "Namaste7",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 8,
      userName: "Namaste8",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 9,
      userName: "Namaste9",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 10,
      userName: "Namaste10",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 11,
      userName: "Namaste11",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 12,
      userName: "Namaste12",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 13,
      userName: "Namaste13",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
    {
      id: 14,
      userName: "Namaste14",
      type: "Joined",
      bonuses: [
        {
          bonus: 0.01,
          symbol: "TON",
          symbolIcon: (
            <Iconify
              icon={"token:ton"}
              sx={{
                background: theme.palette.primary.main,
                color: "#fff",
                borderRadius: 50,
                padding: "3px 3px 1px",
              }}
              size={18}
            />
          ),
          color: theme.palette.warning.main,
        },
        {
          bonus: 1000,
          symbol: "TNX",
          symbolIcon: <TNXIcon size={18} />,
          color: theme.palette.success.main,
        },
      ],
    },
  ];
  const rowHeight = 40;
  const defaultHeader = [
    {
      field: "userName",
      flex: 1,
      minWidth: 120,
      headerName: t("Name"),
      renderCell: ({ row }) => <Typography noWrap>{row.userName}</Typography>,
    },
    {
      field: "type",
      flex: 1,
      minWidth: 120,
      headerName: t("Type"),
      renderCell: ({ row }) => <Typography noWrap>{row.userName}</Typography>,
    },
    {
      field: "bonuses",
      align: "right",
      headerAlign: "right",
      flex: 1,
      minWidth: 120,
      headerName: t("Bonus"),
      renderCell: ({ row }) => (
        <FlexBetween gap={2}>
          {row.bonuses.map((bonus) => (
            <Typography sx={{ color: bonus.color }}>+{bonus.bonus}</Typography>
          ))}
        </FlexBetween>
      ),
    },
  ];
  return (
    <Stack mt={2} px={1}>
      <Typography textAlign={'center'} mb={2} variant='h5' fontWeight={700}>
        {t("Total friend")} ({data?.length})
      </Typography>
      <Stack spacing={1} maxHeight={'calc(50vh - 140px)'} sx={{ overflowY: 'scroll', borderRadius: '0.6rem' }}>
        {
          data.map((item, index) => <FlexBetween gap={1} p={1} key={index} sx={{ background: '#FFEFC9', borderRadius: '0.6rem', }}>
            <Avatar src="images/pepton-1.png" sx={{
              width: 30, height: 30, background: '#FE6003', padding: '4px 2px 0px',
              borderRadius: '0.6rem'
            }} />
            <Typography fontWeight={600}>{item.userName}</Typography>
            <Box flexGrow={1} />
            <Typography variant="h5" fontWeight={600}>+{item?.bonuses?.[0].bonus}</Typography>
            <Avatar src="images/pepton-1.png" sx={{
              width: 20, height: 20, background: '#FE6003', padding: '2px', border: '1px solid #FF6000', boxShadow: '1px 1px 0px #C54B02', position: 'relative',
              '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: '50%',
                right: 0,
                background: 'rgba(255,255,255,0.15)'
              },
            }} />
          </FlexBetween>)
        }
      </Stack>
      {/* <AppTable
        columns={defaultHeader}
        data={data}
        rowHeight={rowHeight}
        rowSpacing={false}
        defaultPageSize={10}
        sx={{
          "& .MuiDataGrid-cell": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            border: "none",
            borderBottom: `1px solid ${theme.palette.divider} !important`,
            padding: "0px 0.7rem !important",
          },
          "& .MuiDataGrid-topContainer": {
            "&::after": {
              display: "none !important",
            },
          },
          "& .MuiDataGrid-columnHeaders": {
            borderRadius: "0.5rem !important",
            overflow: "hidden",
          },
          "& .MuiDataGrid-columnHeader": {
            color: theme.palette.text.secondary,
            background: theme.palette.background.paper,
            height: "45px !important",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      /> */}
    </Stack>
  );
};

const RefContainer = styled(Stack)(({ theme }) => ({
  margin: "auto",
  padding: "1rem",
  paddingTop: '120px',
  // background: "url('images/paper-bg.svg') 50% 100% no-repeat",
  backgroundSize: 'contain',
  width: 'calc(100% - 2rem)',
  maxHeight: '160vw'
}));
const ReferralBox = styled(Box)(({ theme }) => ({
  borderRadius: "0.7rem",
  background: "#feeabb",
  marginBottom: "1rem",
  marginTop: "0.5rem",
}));
