import { LoadingButton } from "@mui/lab";
import { alpha, Box, Button, styled } from "@mui/material";

export const TitleModalBox = styled(Box)(({ theme }) => ({
  backgroundImage: "url(/images/modal-title-bg.svg)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  aspectRatio: "4.8",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.25rem",
  padding: "1rem 2rem",
}));

export const ButtonWithdraw = styled(Button)(({ theme }) => ({
  position: "relative",
  borderRadius: "1rem",
  background: "#F1FBFF",
  border: `1px solid #1CB7F4`,
  padding: "0.5rem 1rem 0.5rem 0.5rem",
  minHeight: "unset",
  overflow: "visible",
  boxShadow:
    "3px 3px 0px #0082B7",
  overflow: 'hidden',
  "&:hover, &:active": {
    background: "#E1F3FF",
    boxShadow:
      "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
  },
  '&:before': {
    content: '""',
    top: 0,
    left: 0,
    bottom: '50%',
    right: 0,
    position: 'absolute',
    background: alpha('#1bb6f4', 0.15)
  }
}));

export const ButtonConvert = styled(Button)(({ theme }) => ({
  position: "relative",
  borderRadius: "1rem",
  background: "#FFF8F1",
  border: `1px solid #FE6003`,
  padding: "0.5rem 1rem 0.5rem 0.5rem",
  minHeight: "unset",
  overflow: "visible",
  boxShadow:
    "3px 3px 0px #CC4900",
  overflow: 'hidden',
  "&:hover, &:active": {
    background: "#E1F3FF",
    boxShadow:
      "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
  },
  '&:before': {
    content: '""',
    top: 0,
    left: 0,
    bottom: '50%',
    right: 0,
    position: 'absolute',
    background: alpha('#CC4900', 0.15)
  }
}));

export const ModalContentBox = styled(Box)(({ theme }) => ({
  background: "#FFDDB5",
  position: "relative",
  borderRadius: "0.875rem",
  border: `1px dashed #000`,
  padding: "0.875rem",
  marginTop: "1rem",
  marginBottom: "1rem",
  "& .MuiInputBase-root": {
    background: "#000000",
    borderRadius: "0.5rem",
  },
  input: { color: "#FFFFFF" },
  fieldset: {
    borderColor: "#FFFFFF",
    borderRadius: "0.5rem",
  },
}));

export const ConvertButton = styled(Button)(({ theme }) => ({
  minWidth: "unset",
  minHeight: "unset",
  padding: "0.35rem 1rem",
  background: "#F6C434",
  color: '#000',
  boxShadow: "inset 0px -2px 0px #E58725, inset 0px 2px 0px #F6E98A",
  "&:hover, &:active": {
    background: "#F6C434",
    boxShadow: "inset 0px -3px 0px #E58725, inset 0px 3px 0px #F6E98A",
  },
}));

export const ClaimButton = styled(LoadingButton)(({ theme }) => ({
  minWidth: "100px",
  maxWidth: '100px',
  minHeight: "35px",
  padding: "0px 1rem",
  background: "#FF6000 !important",
  color: '#fff',
  fontSize: '1.2rem',
  border: '1px solid #CC4900',
  fontWeight: 700,
  borderRadius: '0.6rem',
  boxShadow: "2px 2px 0px #CC4900 !important",
  overflow: 'hidden',
  position: 'relative',
  transition: 'all 0.1s ease-in-out',
  '&:before': {
    content: '""',
    width: '100%',
    height: '50%',
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'rgba(255,255,255,0.15)'
  },
  "&:active": {
    transform: 'scale(0.95)'
  },
}));

export const ButtonAction = styled(LoadingButton)(({ theme }) => ({
  position: "relative",
  borderRadius: "0.5rem",
  background: "#F6A834",
  border: `2px solid #000`,
  boxShadow: "inset 0px -2px 0px #E58725, inset 0px 2px 0px #F6E98A",
  "&:hover, &:active": {
    background: "#F6A834",
    boxShadow: "inset 0px -2px 0px #E58725, inset 0px 2px 0px #F6E98A",
  },
}));

export const BinaryButton = styled(Button)(({ theme }) => ({
  background: '#0098E9',
  border: '1.5px solid #0098E9',
  minWidth: 'unset',
  minHeight: 'unset',
  boxShadow: '2px 1px 0px #00689F',
  position: 'relative',
  borderRadius: "0.6rem",
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: '50%',
    right: 0,
    background: 'rgba(255,255,255,0.1)'
  }
}));

export const SecondaryButton = styled(LoadingButton)(({ theme }) => ({
  background: '#FF7521 !important',
  border: '1.5px solid #CC4900',
  minWidth: 'unset',
  minHeight: 'unset',
  boxShadow: '2px 1px 0px #CC4900 !important',
  position: 'relative',
  borderRadius: "0.6rem",
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: '50%',
    right: 0,
    background: 'rgba(255,255,255,0.1)'
  },
  transition: 'all 0.1s ease-in-out',
  '&:active': {
    transform: 'scale(0.95)',

  },
  '&.Mui-disabled': {
    background: '#FFF6E1 !important',
    boxShadow: 'none !important',
    borderColor: "#aaa !important",
    '& *': {
      color: '#aaa !important',
    }
  }
}));

export const BlackButton = styled(LoadingButton)(({ theme }) => ({
  background: '#000 !important',
  border: '1.5px solid #000',
  minWidth: 'unset',
  minHeight: 'unset',
  boxShadow: '2px 1px 0px #000 !important',
  position: 'relative',
  borderRadius: "0.6rem",
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: '50%',
    right: 0,
    background: 'rgba(255,255,255,0.1)'
  },
  transition: 'all 0.1s ease-in-out',
  '&:active': {
    transform: 'scale(0.95)',

  },
  '&.Mui-disabled': {
    background: '#FFF6E1 !important',
    boxShadow: 'none !important',
    borderColor: "#aaa !important",
    '& *': {
      color: '#aaa !important',
    }
  }
}));

export const SecondaryButtonContainer = styled(Box)(({ theme }) => ({
  '& button': {
    background: '#FF7521 !important',
    border: '1.5px solid #CC4900',
    minWidth: 'unset',
    minHeight: 'unset',
    boxShadow: '2px 1px 0px #CC4900 !important',
    position: 'relative',
    '& svg': {
      display: 'none',
    },
    '& *': {
      fontFamily: "'Mali', sans-serif",
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: '50%',
      right: 0,
      background: 'rgba(255,255,255,0.1)'
    },
    transition: 'all 0.1s ease-in-out',
    '&:active': {
      transform: 'scale(0.95)',

    }
  }
}));
