import {
  Avatar,
  Box,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import AppTable from "components/AppTable";
import { FlexRowAlign } from "components/flexbox";
import { t } from "i18next";
import moment from "moment";
import useAxios from "../../hooks/useAxios";

import { useEffect, useState } from "react";
import useSettings from "../../hooks/useSettings";
import useUserActivities from "../../hooks/useUserActivities";
import { TitleBox } from "./styled";
import { fCurrency } from "../../utils/format";
import { BorderBottom } from "@mui/icons-material";

export default function History() {
  const theme = useTheme();

  return (
    <QuestContainer>
      <Stack spacing={1} position={"relative"} zIndex={10} sx={{ flexGrow: 1 }}>
        <HistoryList />
      </Stack>
    </QuestContainer>
  );
}

const HistoryList = () => {
  const theme = useTheme();
  const { settings } = useSettings();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { callApi } = useAxios();
  const rowHeight = 30;
  const defaultHeader = [
    {
      field: "createdAt",
      flex: 1,
      minWidth: 80,
      headerName: t("Time"),
      renderCell: ({ row }) => (
        <Stack>
          <Typography textAlign={"center"}>
            {moment(row.createdAt).format("DD MMM mm:HH")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "action",
      flex: 1,
      minWidth: 100,
      headerName: t("Type"),
    },
    {
      field: "amount",
      flex: 1,
      minWidth: 100,
      headerName: t("Reward"),
      renderCell: ({ row }) => (
        <FlexRowAlign sx={{ gap: 0.5 }}>
          <Typography>{fCurrency(row.amount)}</Typography>
        </FlexRowAlign>
      ),
    },
    // {
    //   field: "Hash",
    //   flex: 1,
    //   minWidth: 100,
    //   headerName: t("Hash"),
    //   headerAlign: "right",
    //   renderCell: ({ row }) =>
    //     row.transactionHash && (
    //       <Link
    //         onClick={() =>
    //           window.open(
    //             `https://tonviewer.com/transaction/${row.transactionHash}`,
    //             "_blank",
    //           )
    //         }
    //         sx={{ cursor: "pointer", width: "100%" }}>
    //         <FlexRowAlign width={"100%"} justifyContent='flex-end'>
    //           <Typography noWrap maxWidth={50}>
    //             {row.transactionHash}
    //           </Typography>
    //           <Iconify icon='ph:link' />
    //         </FlexRowAlign>
    //       </Link>
    //     ),
    // },
  ];

  const { userActivities, fetchUserActivities } = useUserActivities(page);

  useEffect(() => {
    if (!userActivities) return;
    setLoading(true);
    setTotal(userActivities?.total ?? 0);
    setData(
      userActivities?.data?.map((item, index) => ({
        ...item,
        id: `${item.createdAt}-${index}-${item.transactionHash}`,
      })) || [],
    );
    setLoading(false);
  }, [userActivities]);

  // const handleGetHistory = async (page = 1) => {
  //   try {
  //     setLoading(true);
  //     const res = await callApi(
  //       "get",
  //       `/user/activities?page=${page}&limit=10`
  //     );
  //     setTotal(0);
  //     setData([]);

  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   handleGetHistory();
  // }, []);

  return (
    <HistoryContainer>
      <Stack gap={2} sx={{
        height: '75vh',
        backgroundSize: 'cover',
        padding: '0px 1.5rem 0px 1rem',
        position: 'relative',
        zIndex: 1000
      }}>
        <TitleBox>
          <Stack alignItems={'center'} justifyContent={'center'} sx={{ position: 'absolute', top: 10, right: 0, left: 0, bottom: 0 }}>
            <Typography sx={{ color: "#fff" }} variant='h4' fontWeight={700}>
              {t("History")}
            </Typography>
          </Stack>
          <img src="images/title-bg.png" style={{
            maxWidth: '444px',
            width: '100%',
            display: 'block',
            margin: 'auto'
          }} />
        </TitleBox>
        <HistoryBox >
          <AppTable
            columns={defaultHeader}
            data={data}
            rowHeight={rowHeight}
            rowSpacing={false}
            defaultPageSize={10}
            onPageChange={(page) => setPage(page + 1)}
            itemCount={total}
            loading={loading}
            disableRowHover={true}
            height={'50vh'}
            sx={{
              "& .MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
                width: "100%",
                border: "none",
                padding: "0px 0.7rem !important",
              },
              "& .MuiDataGrid-topContainer": {
                "&::after": {
                  display: "none !important",
                },
              },
              "& .MuiDataGrid-columnHeaders": {
                overflow: "hidden",
              },
              "& .MuiDataGrid-columnHeader": {
                color: theme.palette.text.primary,
                fontSize: "1rem",
                height: "45px !important",
              },
              "& .MuiDataGrid-columnSeparator": {
                display: "none",
              },
            }}
          />
        </HistoryBox>
      </Stack>
      <Stack sx={{ position: 'absolute', bottom: 20, left: 0, width: '100%', top: 'auto' }}>
        <img src="images/paper-bg.png" style={{ marginBottom: '-20%' }} />
        <img src="images/paper-bg.png" />
      </Stack>
    </HistoryContainer>
  );
};

const QuestContainer = styled(Stack)(({ theme }) => ({
  margin: "auto",
  minHeight: "calc(70vh - 114px)",
  padding: "2rem",
  position: 'relative'
}));

const HistoryBox = styled(Box)(({ theme }) => ({
  borderRadius: "1rem",
  background: "#fff7e1",
  border: `1px solid #C56B21`,
  padding: "0px 0.875rem",
  boxShadow:
    "3px 3px 0px #71360B",
  "& .MuiDataGrid-columnHeaders": {
    background: "#FFF7E1",
    borderBottom: '1px solid #C56B21',
    height: 40
  },
  "& .MuiDataGrid-row": {
    background: "#FFEEC9",
    borderRadius: "0.5rem",
    marginTop: "0.5rem",
  },
  "& .MuiSvgIcon-root ": {
    color: "#FFFFFF",
  },
  '& .MuiPaginationItem-root ': {
    minWidth: 25,
    height: 25,
    border: '1px solid #C56B21',
    color: '#C56B21',
    fontWeight: 600,
    "&.Mui-selected": {
      border: '1px solid #374151',
      color: "#fff",
    },
    '& .MuiSvgIcon-root': {
      color: '#C56B21'
    }
  }
}));

const HistoryContainer = styled(Stack)(({ theme }) => ({
  margin: "auto",
  minHeight: "calc(90vh - 114px)",
  padding: "1rem",
  paddingTop: '120px',

}));
