import {
  Box,
  Card,
  IconButton,
  Modal,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import Iconify from "../../components/Iconify";
import { FlexBetween, FlexRowAlign } from "components/flexbox";
import { t } from "i18next";
import CheckedIcon from "icons/CheckedIcon";
import useAxios from "./../../hooks/useAxios";
import useSettings from "./../../hooks/useSettings";
import useSocket from "./../../hooks/useSocket";
import useUserQuest from "../../hooks/useUserQuest";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useUserData from "../../hooks/useUserData";
import { useTonWallet, TonConnectButton } from "@tonconnect/ui-react";
import { TitleBox, WheatBox } from "./styled";
import { BinaryButton, BlackButton, SecondaryButton } from "../../page-sections/homepage/styled";
import Decimal from "decimal.js";

const MediaImage = [
  {
    id: 'all',
    image: <SecondaryButton sx={{ width: 40, height: 40, padding: 0, }}>
      <img src="/images/icon-home.png" width={'100%'} height={'100%'} style={{ transform: 'scale(1.2)' }} />
    </SecondaryButton>
  },
  {
    id: 'x.com',
    image: <BlackButton sx={{ width: 40, height: 40, padding: '5px' }}>
      <Iconify icon={'pajamas:twitter'} size={30} />
    </BlackButton>
  },
  {
    id: 't.me',
    image: <BinaryButton sx={{ width: 40, height: 40, padding: '5px' }}>
      <Iconify icon="bxl:telegram" size={30} />
    </BinaryButton>
  },

]

const handleGetMediaImage = (searchKey) => {
  const output = MediaImage.find(item => searchKey?.includes(item.id))
  return output?.image || MediaImage[0]?.image
}

export default function Quest() {
  const theme = useTheme();
  const { callApi } = useAxios();
  const { userQuest, fetchUserQuest } = useUserQuest();
  const { userData, fetchUserData } = useUserData();
  const wallet = useTonWallet();

  const [isDoing, setIsDoing] = useState({});
  const [isVerifying, setIsVerifying] = useState({});
  const [isOpenConnectPopUp, setIsOpenConnectPopUp] = useState(false);

  const quests = userQuest ?? [];
  const connectQuest = quests.find((q) => q.id == 1);

  useEffect(() => {
    console.log(connectQuest?.isDone, wallet);
    if (connectQuest?.isDone == false && wallet) {
      (async () => {
        if (wallet) {
          try {
            await callApi("post", "/user/sucess-quest", {
              questId: 1,
            });
            await fetchUserQuest();
            await fetchUserData();
            toast.success("🎉 Quest Completed", {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } catch (error) {
            if (error.response && error.response.status === 400) {
              toast.error(`Error: ${error.response.data.message}`, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error("An unexpected error occurred.", {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        }
      })();
      setIsOpenConnectPopUp(false);
    }
  }, [wallet, connectQuest]);

  useEffect(() => {
    if (!userQuest) return;
    const isDoingTemp = {};
    const isVerifyingTemp = {};

    quests.forEach((_, index) => {
      isDoingTemp[index] = false;
      isVerifyingTemp[index] = false;
    });
    setIsDoing(isDoingTemp);
    setIsVerifying(isVerifyingTemp);
  }, [userQuest]);
  const handleDoQuest = async (item, index) => {
    if (item.id == 1 && item.isDone == false) {
      setIsOpenConnectPopUp(true);
    } else if (
      item.isDone === false &&
      !!item.hyperLink &&
      (!item.isDoing)
    ) {
      if (item.hyperLink.startsWith('/')){
        window.location.href = item.hyperLink;
      } else {
        window.open(item.hyperLink, "_blank");
        await callApi("post", "/user/do-quest", {
          questId: item.id,
        });
        await fetchUserQuest();
      }
    }
  };
  const handleVerifyQuest = async (item, index) => {
    setIsVerifying((prevState) => ({
      ...prevState,
      [index]: true,
    }));
    try {
      await callApi("post", "/user/sucess-quest", {
        questId: item.id,
      });
      await fetchUserQuest();
      await fetchUserData();
      toast.success("🎉 Quest Completed", {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(`Error: ${error.response.data.message}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("An unexpected error occurred.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setIsVerifying((prevState) => ({
        ...prevState,
        [index]: false,
      }));
    }
  };

  return (
    <QuestContainer spacing={1} position={'relative'}>
      <ConnectWalletPopup
        openConnectQuest={isOpenConnectPopUp}
        closeConnectQuest={() => setIsOpenConnectPopUp(false)}
      />
      <TitleBox>
        <Stack alignItems={'center'} justifyContent={'center'} sx={{ position: 'absolute', top: 10, right: 0, left: 0, bottom: 0 }}>
          <Typography variant='h4' sx={{ color: "#fff", fontWeight: 600 }}>
            {t("Quests")}
          </Typography>
          <Typography variant="caption" sx={{ color: "#fff", fontWeight: 600 }}>
            {t("Complete all quests below to earn NOT/CEN")}
          </Typography>
        </Stack>
        <img src="images/title-bg.png" style={{
          maxWidth: '444px',
          width: '100%',
          display: 'block',
          margin: 'auto'
        }} />
      </TitleBox>
      <Stack spacing={2} sx={{
        height: 'calc(75vh)',
        padding: '3rem 2.5rem 0px 1.5rem',
        backgroundSize: 'cover',
        marginTop: '-2rem !important',
        overflow: 'scroll',
        position: 'relative',
        zIndex: 1000
      }}>
        {quests.map((item, index) => (
          <WheatBox onClick={() => handleDoQuest(item, index)} key={item.name}>
            <FlexBetween gap={1}>
              {
                handleGetMediaImage(item?.hyperLink)
              }
              <Stack pl={1}>
                <Typography
                  mb={1}
                  color={
                    item.isDone === true ? "text.secondary" : "text.primary"
                  }>
                  {item.name}
                </Typography>
                <FlexRowAlign gap={3} justifyContent='flex-start'>
                  <FlexRowAlign justifyContent="flex-start" gap={1}>
                    <Iconify
                      icon={"token:ton"}
                      sx={{
                        background: "#000",
                        color: "#fff",
                        borderRadius: 50,
                        padding: "3px 3px 1px",
                        transform: "rotate(-180deg)",
                      }}
                      size={18}
                    />
                    <Typography fontWeight={600}>
                      {`${item.notAmount} NOT`}
                    </Typography>
                  </FlexRowAlign>
                  <FlexRowAlign justifyContent="flex-start" gap={1}>
                    <Box
                      component='img'
                      src={`/images/pepton-1.png`}
                      sx={{
                        width: 18, height: 18
                      }}
                    />
                    <Typography fontWeight={600}>
                      {`${item.cenAmount} CEN`}
                    </Typography>
                  </FlexRowAlign>
                </FlexRowAlign>
              </Stack>
              <Box flexGrow={1} />
              {item.isDone === true ? (
              <FlexRowAlign gap={1}>
                <Typography sx={{ color: theme.palette.success.main }}>
                  {t("Done")}
                </Typography>
                <CheckedIcon
                  size={20}
                  sx={{ color: theme.palette.success.main }}
                />
              </FlexRowAlign>
            ) : (
              <>
                 {!!item.hyperLink && item.isDoing == false ? (
                    <ArrowRightIcon sx={{ color: '#71360B' }} />
                  ) : (
                    <>
                      {!!item.hyperLink && item.isDoing == true ? (
                        <FlexRowAlign gap={1}>
                          <LoadingButton
                            loading={isVerifying[index]}
                            onClick={() => handleVerifyQuest(item, index)}
                            fullWidth
                            variant='outlined'>
                            <Typography>{"Verify"}</Typography>
                          </LoadingButton>
                        </FlexRowAlign>
                      ) : (
                        <></>
                      )}
                    </>
                )}
              </>
            )}
            </FlexBetween>
          </WheatBox>
        ))}
      </Stack>
      <Stack sx={{ position: 'absolute', bottom: 20, left: 0, width: '100%', top: 'auto' }}>
        <img src="images/paper-bg.png" style={{ marginBottom: '-20%' }} />
        <img src="images/paper-bg.png" />
      </Stack>
    </QuestContainer >
  );
}

const ConnectWalletPopup = ({ openConnectQuest, closeConnectQuest }) => {
  const theme = useTheme();
  return (
    <>
      <Modal
        open={openConnectQuest}
        sx={{
          padding: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={closeConnectQuest}>
        <Stack
          p={2}
          sx={{
            background: theme.palette.background.default,
            borderRadius: "0.7rem",
            maxWidth: 400,
            minHeight: 200,
            width: "100%",
            border: `1px solid ${theme.palette.divider}`,
            position: "relative",
          }}>
          <IconButton
            onClick={closeConnectQuest}
            sx={{ position: "absolute", top: 3, right: 3 }}>
            <Iconify icon={"ic:round-close"} size={25} />
          </IconButton>
          <Iconify
            icon='solar:wallet-2-bold-duotone'
            size={40}
            sx={{ margin: "auto", opacity: 0.3 }}
          />
          <Typography
            variant='h5'
            textAlign={"center"}
            color={"text.secondary"}>
            {t("Please connect wallet")}
          </Typography>
          <TonConnectButton />
        </Stack>
      </Modal>
    </>
  );
};

const QuestContainer = styled(Stack)(({ theme }) => ({
  margin: "auto",
  padding: "1rem",
  paddingTop: '120px',
  backgroundSize: 'contain',
  width: 'calc(100% - 2rem)',
}));
