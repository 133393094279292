import { Outlet, useSearchParams } from "react-router-dom";
// CUSTOM COMPONENTS
import LayoutSideBar from "./components/DashboardSidebar";
import LayoutBodyWrapper from "./components/LayoutBodyWrapper";
// DASHBOARD LAYOUT BASED CONTEXT PROVIDER
import LayoutProvider from "./context/layoutContext";
import DashboardFooter from "./components/DashboardFooter";
import useSettings from "../../hooks/useSettings";
import { useEffect } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import FlexBetween from "../../components/flexbox/FlexBetween";
import Iconify from "../../components/Iconify";
import { useTelegram } from "contexts/TelegramContext";
import { WheatBox } from "../../pages/mobile/styled";

export default function MobileLayout({ children }) {
  const theme = useTheme();
  const { settings, saveSettings } = useSettings();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, webApp } = useTelegram();
  const isLocalhost = () => {
    const hostname = window.location.hostname;
    return hostname === 'localhost' || hostname === '127.0.0.1';
  };
  console.log("webApp?.platform",webApp?.platform)
  const isMobile = isLocalhost() || webApp?.platform === "tdesktop"  || webApp?.platform === "macos" || webApp?.platform === "windows"  || webApp?.platform === "ios" || webApp?.platform === "android";
  // const isMobile = true;

  useEffect(() => {
    const temp = searchParams.get("token");
    if (settings?.token !== temp && !!temp && temp?.length > 0) {
      saveSettings({ ...settings, token: temp });
    }
  }, [settings]);

  return (
    <LayoutProvider>
      <LayoutBodyWrapper>
        {isMobile ? (
          <>
            <Box>{children || <Outlet />}</Box>
            <DashboardFooter />
          </>
        ) : (
          <>
            <Stack
              sx={{
                backgroundImage: "url(/images/home-bg-1.jpg)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "top center",
              }}
              alignItems={"center"}
              justifyContent={"flex-end"}
              height={"100vh"}>
              {/* <WheatBox mb={"20vh"}>
                <Typography fontWeight={600} variant='h6'>
                  {t("Please login on your mobile device")}
                </Typography>
                <FlexBetween mt={"1rem"} gap={2} justifyContent={"center"}>
                  <FlexBetween gap={1}>
                    <Iconify
                      icon={"token:ton"}
                      sx={{
                        background: theme.palette.primary.main,
                        color: "#fff",
                        borderRadius: 50,
                        padding: "3px 3px 1px",
                      }}
                      size={20}
                    />
                    <Typography fontWeight={600} variant='h6'>
                      TON
                    </Typography>
                  </FlexBetween>
                  <FlexBetween gap={1}>
                    <Iconify
                      icon={"token:ton"}
                      sx={{
                        background: "#000",
                        color: "#fff",
                        borderRadius: 50,
                        padding: "3px 3px 1px",
                        transform: "rotate(-180deg)",
                      }}
                      size={20}
                    />
                    <Typography fontWeight={600} variant='h6'>
                      NOT
                    </Typography>
                  </FlexBetween>
                </FlexBetween>
              </WheatBox> */}
            </Stack>
          </>
        )}
      </LayoutBodyWrapper>
    </LayoutProvider>
  );
}
