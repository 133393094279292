export const resources = {
  en: {
    translation: {
      Profiles: 'Profiles',
      Dashboard: 'Dashboard',
      Logistics: 'Logistics',
      Ecommerce: 'Ecommerce',
      Management: 'Management',
      'Analytics 1': 'Analytics 1',
      'Analytics 2': 'Analytics 2',
      'Finance 1': 'Finance 1',
      'Finance 2': 'Finance 2',
      Accounts: 'Accounts',
      'User & Contact': 'User & Contact',
      Invoice: 'Invoice',
      Users: 'Users',
      Sessions: 'Sessions',
      'Bounce Rate': 'Bounce Rate',
      'Session Duration': 'Session Duration',
      'Live Online User': 'Live Online User',
      'Page views': 'Page views',
      'Visits by Top Referral Source': 'Visits by Top Referral Source',
      'Session by browser': 'Session by browser',
      'View Details': 'View Details',
      'Home': 'Home',
      'Balance': 'Balance',
      'Withdraw': 'Withdraw',
      'What is TONEE?': 'What is TONEE?',
      'Claim': 'Claim',
      'Boost': 'Boost',
      'Hashrate': 'Hashrate',
      'Mining': 'Mining',
      'Cancel': 'Cancel',
      'Confirm': 'Confirm',
      'Enter {{symbol}} wallet address to receive your earning': 'Enter {{symbol}} wallet address to receive your earning',
      'Minimum amount': 'Minimum amount',
      'Enter amount of {{symbol}} here': 'Enter amount of {{symbol}} here',
      '{{symbol}} wallet address': '{{symbol}} wallet address',
      'We recommend using personal {{symbol}} wallet': 'We recommend using personal {{symbol}} wallet',
      'Rent a PRO Miner for 30 days': 'Rent a PRO Miner for 30 days',
      'Here you can rent PRO Miner for 30 days. This investment profitability is': 'Here you can rent PRO Miner for 30 days. This investment profitability is',
      'per day': 'per day',
      'and': 'and',
      'for 30 days': 'for 30 days',
      'Miner Name': 'Miner Name',
      'Mining Speed': 'Mining Speed',
      'Rent Period': 'Rent Period',
      'days': 'days',
      'Rent Price': 'Rent Price',
      'Daily Profit': 'Daily Profit',
      'Total Profit': 'Total Profit',
      'You pay': 'You pay',
      'Bonus for PRO Miner owners': 'Bonus for PRO Miner owners',
      'Connect wallet': 'Connect wallet',
      'Initial Bonus': 'Initial Bonus',
      'Done': 'Done',
      'Join the Telegram community': 'Join the Telegram community',
      'Follow TONIX on X': 'Follow TONIX on X',
      'Follow TonVM on X': 'Follow TonVM on X',
      'Subscribe to Telegram Announcements': 'Subscribe to Telegram Announcements',
      'Invite your first friend': 'Invite your first friend',
      'Rent your first power booster': 'Rent your first power booster',
      'Quests': 'Quests',
      'Referral': 'Referral',
      'The more friends you invite, the more rewards you earn. Earn bonuses when your friends rent boosts or complete tasks.': 'The more friends you invite, the more rewards you earn. Earn bonuses when your friends rent boosts or complete tasks.',
      'Invite link': 'Invite link',
      'Each friend is invited': 'Each friend is invited',
      'Each boost is purchased by a friend': 'Each boost is purchased by a friend',
      'Each mission is completed by a friend': 'Each mission is completed by a friend',
      'Name': 'Name',
      'Type': 'Type',
      'Bonus': 'Bonus',
      'History': 'History',
      'Time': 'Time',
      'Amount': 'Amount',
      'Copied': 'Copied',
      'Please login on your mobile device': 'Please login on your mobile device'
    }
  },
  es: {
    translation: {
      Dashboard: 'Panel',
      Profiles: 'Perfiles',
      Management: 'Gestión',
      Logistics: 'Logística',
      Ecommerce: 'Comercio electrónico',
      'Analytics 1': 'Analítica 1',
      'Analytics 2': 'Analítica 2',
      'Finance 1': 'Finanzas 1',
      'Finance 2': 'Finanzas 2',
      Accounts: 'Cuentas',
      'User & Contact': 'Usuario y Contacto',
      Invoice: 'Factura',
      Users: 'Usuarias',
      Sessions: 'Sesiones',
      'Bounce Rate': 'Porcentaje',
      'Session Duration': 'Duración de la sesión',
      'Live Online User': 'Usuario en línea en vivo',
      'Page views': 'Visitas a la página',
      'Visits by Top Referral Source': 'Visitas por principal fuente de referencia',
      'Session by browser': 'Sesión por navegador',
      'View Details': 'Ver detalles'
    }
  }
};