// CUSTOM ICON COMPONENT
import { t } from 'i18next';
import HistoryIcon from 'icons/HistoryIcon';
import HomeIcon from 'icons/HomeIcon';
import QuestIcon from 'icons/QuestIcon';
import ReferralIcon from 'icons/ReferralIcon';
export const navigation = [
  {
    name: 'Quest',
    Icon: <QuestIcon size={18} />,
    iconImage: '/images/icon-quest.png',
    path: '/quest'
  },
  {
    name: 'Upgrade',
    Icon: <QuestIcon size={18} />,
    iconImage: '/images/icon-upgrade.png',
    path: '/upgrade'
  },

  {
    name: 'Home',
    Icon: <HomeIcon size={18} />,
    iconImage: '/images/icon-home.png',
    path: ''
  },
  {
    name: 'Referral',
    Icon: <ReferralIcon size={18} />,
    iconImage: '/images/icon-referral.png',
    path: '/referral'
  },
  {
    name: 'History',
    Icon: <HistoryIcon size={18} />,
    iconImage: '/images/icon-history.png',
    path: '/history'
  },
];