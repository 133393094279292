import { lazy } from "react";
import Loadable from "./Loadable";
import { PublicRoutes } from "./public";
import MobileLayout from "layouts/layout-mobile/MobileLayout";
import { MobileRoutes } from "./mobile";
import { Dashboard } from "../pages/mobile/dashboard";

// GLOBAL ERROR PAGE
const ErrorPage = Loadable(lazy(() => import("pages/404")));
// MOBILE APP

export const routes = () => {
  return [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    // INITIAL / INDEX PAGE
    {
      path: "/",
      element: <MobileLayout />,
      children: [...MobileRoutes],
    },
    // GLOBAL ERROR PAGE
    {
      path: "*",
      element: <ErrorPage />,
    },
    // PAGES ROUTES
    ...PublicRoutes,
  ];
};
