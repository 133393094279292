import { Box, Stack, styled, Typography } from "@mui/material";

export const BoxContainer = styled(Stack)(({ theme }) => ({
  margin: "auto",
  padding: "1rem",
  paddingTop: '140px',
  paddingBottom: '120px'
}));

export const TypographyOutline = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
  WebkitTextFillColor: "white",
  WebkitTextStroke: "1px black",
  paintOrder: "stroke fill",
}));

export const TitleBox = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 10000,
}));

export const BrownBox = styled(Box)(({ theme }) => ({
  borderRadius: "1rem",
  background: "#FFF6E1",
  border: `1px solid #C56B21`,
  padding: "0.875rem",
  boxShadow:
    "3px 3px 0px #71360B",
}));

export const WheatBox = styled(Box)(({ theme }) => ({
  borderRadius: "1rem",
  background: "#FFF6E1",
  border: `1px solid #C56D21`,
  padding: "0.875rem",
  boxShadow:
    "3px 3px 0px #71360B",
}));

export const OrangeBox = styled(Box)(({ theme }) => ({
  borderRadius: "1rem",
  background: "#FFC671",
  border: `1px solid #000`,
  padding: "0.875rem 0px",
  boxShadow:
    "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
}));

export const BlueBox = styled(Box)(({ theme }) => ({
  borderRadius: "1rem",
  background: "#81A4FF",
  border: `1px solid #000`,
  padding: "0.875rem",
  boxShadow:
    "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
}));

