import { SvgIcon } from '@mui/material';
const ReferralIcon = props => {
    const { size } = props
    return <SvgIcon viewBox="0 0 25 17" {...props} sx={{ width: size || 25, height: size || 25 }}>
        <path d="M9.02563 7.36812C8.16054 6.50781 7.625 5.31645 7.625 4C7.625 2.68355 8.16054 1.49219 9.02563 0.631879C8.52756 0.387327 7.96732 0.25 7.375 0.25C5.30393 0.25 3.625 1.92893 3.625 4C3.625 6.07107 5.30393 7.75 7.375 7.75C7.96732 7.75 8.52756 7.61267 9.02563 7.36812Z" fill="currentColor" />
        <path d="M8.625 4C8.625 1.92893 10.3039 0.25 12.375 0.25C14.4461 0.25 16.125 1.92893 16.125 4C16.125 6.07107 14.4461 7.75 12.375 7.75C10.3039 7.75 8.625 6.07107 8.625 4Z" fill="currentColor" />
        <path d="M15.7244 0.631879C16.5895 1.49219 17.125 2.68355 17.125 4C17.125 5.31645 16.5895 6.50781 15.7244 7.36812C16.2224 7.61267 16.7827 7.75 17.375 7.75C19.4461 7.75 21.125 6.07107 21.125 4C21.125 1.92893 19.4461 0.25 17.375 0.25C16.7827 0.25 16.2224 0.387327 15.7244 0.631879Z" fill="currentColor" />
        <path d="M5.625 13C5.625 10.9289 7.30393 9.25 9.375 9.25H15.375C17.4461 9.25 19.125 10.9289 19.125 13C19.125 15.0711 17.4461 16.75 15.375 16.75H9.375C7.30393 16.75 5.625 15.0711 5.625 13Z" fill="currentColor" />
        <path d="M0.625 12C0.625 9.92893 2.30393 8.25 4.375 8.25H9.375C6.75165 8.25 4.625 10.3766 4.625 13C4.625 14.0249 4.94958 14.9739 5.50155 15.75H4.375C2.30393 15.75 0.625 14.0711 0.625 12Z" fill="currentColor" />
        <path d="M20.125 13C20.125 14.0249 19.8004 14.9739 19.2484 15.75H20.375C22.4461 15.75 24.125 14.0711 24.125 12C24.125 9.92893 22.4461 8.25 20.375 8.25H15.375C17.9984 8.25 20.125 10.3766 20.125 13Z" fill="currentColor" />
    </SvgIcon>
};
export default ReferralIcon;