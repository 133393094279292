import TelegramProvider, { useTelegram } from "contexts/TelegramContext";
import {
  TonConnectButton,
  useTonWallet,
  useTonConnectUI,
  SendTransactionRequest,
} from "@tonconnect/ui-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  OutlinedInput,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { FlexBetween, FlexRowAlign } from "components/flexbox";
import Decimal from "decimal.js";
import { t } from "i18next";
import ProfitIcon from "icons/ProfitIcon";
import TNXIcon from "icons/TNXIcon";
import { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import useSettings from "../../hooks/useSettings";
import useAxios from "../../hooks/useAxios";
import useUserData from "../../hooks/useUserData";
import { BoxContainer, BrownBox, TitleBox, TypographyOutline } from "./styled";
import { ArrowDropDownRounded, ArrowDropUpRounded } from "@mui/icons-material";
import { Buffer } from 'buffer'
import { beginCell } from "@ton/ton";
import { SecondaryButton, SecondaryButtonContainer } from "../../page-sections/homepage/styled";
import Iconify from "../../components/Iconify";
// global.Buffer = Buffer;

const data = [
  {
    name: "Puilder",
    speed: 10,
    period: 30,
    price: 1.5,
    dailyProfit: 21600,
    totalProfit: 648000,
    paid: 1.5,
    symbol: "TON",
    bonus: 5000,
    bonusSymbol: "CEN",
    bonusSymbolIcon: <TNXIcon size={18} />,
  },
  {
    name: "ChiefPep",
    speed: 100,
    period: 30,
    price: 15,
    dailyProfit: 216000,
    totalProfit: 6480000,
    paid: 15,
    symbol: "TON",
    bonus: 50000,
    bonusSymbol: "CEN",
    bonusSymbolIcon: <TNXIcon size={18} />,
  },
  {
    name: "King Pep",
    speed: 1000,
    period: 30,
    price: 150,
    dailyProfit: 2160000,
    totalProfit: 64800000,
    paid: 150,
    symbol: "TON",
    bonus: 500000,
    bonusSymbol: "CEN",
    bonusSymbolIcon: <TNXIcon size={18} />,
  },
];


export const Upgrade = () => {

  const theme = useTheme();
  const { settings } = useSettings();
  const [minerSelected, setMinerSelected] = useState(data[0]);
  const wallet = useTonWallet();
  const [tonConnectUi] = useTonConnectUI();
  const [isLoading, setIsLoading] = useState(false);
  const [miningAmount, setMiningAmount] = useState(1);
  const { callApi } = useAxios();
  const { userData, fetchUserData } = useUserData();

  const { user, webApp } = useTelegram();
  const amount = useMemo(() => {
    if (miningAmount === '' || isNaN(miningAmount)) return 0;
    return new Decimal(minerSelected.price || 0).mul(miningAmount).toNumber();
  }, [minerSelected, miningAmount]);

  const handleSendTon = useCallback(async () => {
    if (!wallet || !user) return;
    setIsLoading(true);
    try {
      const body = beginCell()
        .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
        .storeStringTail(user.id.toString()) // write our text comment
        .endCell();
      const defaultTx = {
        // The transaction is valid for 10 minutes from now, in unix epoch seconds.
        validUntil: Math.floor(Date.now() / 1000) + 600,
        messages: [
          {
            // The receiver's address.
            address: "UQDYhdTpGxtjIdmbEfqssN45vaJJzQGrr8O8_3u8_kwekRcG",
            // Amount to send in nanoTON. For example, 0.005 TON is 5000000 nanoTON.
            amount: new Decimal(amount).mul(Decimal.pow(10, 9)).toFixed(),
            payload: body.toBoc().toString("base64"),
          },

          // Uncomment the following message to send two messages in one transaction.
          /*
              {
                // Note: Funds sent to this address will not be returned back to the sender.
                address: 'UQAuz15H1ZHrZ_psVrAra7HealMIVeFq0wguqlmFno1f3B-m',
                amount: toNano('0.01').toString(),
              }
              */
        ],
      };
      await tonConnectUi.sendTransaction(defaultTx, {
        notifications: "success",
      });

      // await callApi("post", "/user/check-payment", {
      //   amount: new Decimal(amount).mul(Decimal.pow(10, 9)).toFixed(),
      // });
      // await fetchUserData();

      toast.success(
        "🎉 You have bought cats!",
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    } catch (e) {
      toast.error(e?.message, {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsLoading(false);
    }
  }, [amount, wallet, user]);

  return (
    <BoxContainer gap={2} pb={5}>
      <TitleBox>
        <Stack alignItems={'center'} justifyContent={'center'} sx={{ position: 'absolute', top: 10, right: 0, left: 0, bottom: 0 }}>
          <Typography variant='h4' sx={{ color: "#fff" }} fontWeight={700}>
            {t("Upgrade")}
          </Typography>
          <Typography color={"text.secondary"} sx={{ color: "#fff", fontWeight: 600 }}>
            {t("Choose the perfect choice")}
          </Typography>
        </Stack>
        <img src="images/title-bg.png" style={{
          maxWidth: '444px',
          width: '100%',
          display: 'block',
          margin: 'auto'
        }} />
      </TitleBox>
      <FlexBetween sx={{ transform: 'scale(0.9)' }} gap={1} justifyContent='space-around'>
        {data.map((item, index) => (
          <StyledButton
            onClick={() => setMinerSelected(item)}
            className={minerSelected.name === item.name ? "active" : ""}
            key={item.name}
            variant='outlined'>
            <Box component={"img"} src={`/images/pepton-${index + 1}.png`} sx={{ width: '100%' }} />
            <TypographyOutline
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                bottom: 2,
              }}>
              {item.name}
            </TypographyOutline>
          </StyledButton>
        ))}
      </FlexBetween>
      <BrownBox>
        <Stack>
          <Typography variant="h5" textAlign={'center'} color={"text.secondary"} fontWeight={500}>
            {t("Choose the perfect choice")}
          </Typography>
          <StyledInput
            size='small'
            fullWidth
            placeholder='0'
            sx={{ px: 1 }}
            value={miningAmount}
            onChange={(e) => setMiningAmount(e.target.value)}
            startAdornment={
              <SecondaryButton
                disableRipple
                sx={{ color: "#FFF", padding: 0, minWidth: 30, height: 30 }}
                onClick={() =>
                  setMiningAmount(
                    Decimal.max(0, parseInt(miningAmount || 0) - 1),
                  )
                }>
                <Iconify icon={'akar-icons:minus'} size={20} />
              </SecondaryButton>
            }
            endAdornment={
              <SecondaryButton
                disableRipple
                sx={{ color: "#FFF", padding: 0, minWidth: 30, height: 30 }}
                onClick={() =>
                  setMiningAmount(parseInt(miningAmount || 0) + 1)
                }>
                <Iconify icon={'akar-icons:plus'} size={20} />
              </SecondaryButton>
            }
          />
        </Stack>
        <Stack sx={{
          height: 'fit-content',
          backgroundSize: 'contain',
          justifyContent: 'space-between',
          border: '2px solid #FF6000',
          borderRadius: '1rem',
          overflow: 'hidden',
          pt: 2
        }}>
          <FlexBetween >
            <PriceBox >
              <Typography variant="h5" fontWeight={500} color={"text.secondary"}>{t("Speed")}</Typography>
              <Typography fontWeight={500}>
                <Typography fontWeight={600} component={"span"} variant='h3'>
                  {new Decimal(minerSelected?.speed)
                    .mul(miningAmount)
                    .toNumber()}
                </Typography>{" "}
                Peprate
              </Typography>
            </PriceBox>
            <Divider orientation="vertical" sx={{ height: '30px', borderColor: '#FE6003', position: 'absolute', left: '50%' }} />
            <PriceBox >
              <Typography variant="h5" fontWeight={500} color={"text.secondary"}>{t("Price")}</Typography>
              <Typography fontWeight={500}>
                <Typography fontWeight={600} component={"span"} variant='h3'>
                  {new Decimal(minerSelected?.price).toNumber()}
                </Typography>{" "}
                {minerSelected.symbol}
              </Typography>
            </PriceBox>
          </FlexBetween>
          <Typography mt={2} sx={{ lineHeight: '35px', background: '#FF6000', color: '#fff', fontWeight: 500, width: "fit-content", margin: '2rem auto 0px', borderRadius: '0.7rem 0.7rem 0px 0px', paddingX: '1.5rem' }} textAlign={'center'}>
            Daily Earning: <span style={{ fontSize: '1.2rem' }}>
              {new Decimal(minerSelected?.dailyProfit)
                .mul(miningAmount)
                .toNumber()} CEN
            </span>
          </Typography>
        </Stack>
        <FlexBetween mt={2} mb={2} sx={{ background: '#FEEABB', borderRadius: '0.7rem', padding: '1rem', }}>
          <Typography variant="h6" fontWeight={500}>{t("Total")}</Typography>
          <Typography fontWeight={500}>
            <Typography fontWeight={600} component={"span"} variant='h4'>
              {new Decimal(minerSelected?.paid)
                .mul(miningAmount)
                .toNumber()}{" "}
            </Typography>{" "}
            {minerSelected.symbol}
          </Typography>
        </FlexBetween>
        {wallet?.account ? (
          <LoadingButton
            onClick={handleSendTon}
            loading={isLoading}
            fullWidth
          >
            {t("Upgrade")}
          </LoadingButton>
        ) : (
          <SecondaryButtonContainer>
            <TonConnectButton />
          </SecondaryButtonContainer>
        )}
      </BrownBox>
    </BoxContainer>
  );
};

export const StyledButton = styled(Button)(({ theme }) => ({
  border: `2px solid rgba(0,0,0,0)`,
  background: '#8F8F8F',
  borderRadius: "0.8rem",
  position: "relative",
  flexDirection: "column",
  transform: 'scale(0.95)',
  padding: 0,
  transition: 'all 0.2s ease-in-out',
  img: {
    filter: "saturate(0) brightness(0.8)",
  },
  "&.active": {
    border: `2px solid #CC4900`,
    transform: 'scale(1.05)',
    background: '#1db6f4',
    boxShadow:
      " 3px 3px 0px #CC4900",
    img: {
      filter: "saturate(1)",
    },
  },
}));

export const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  margin: "1rem 0px 1.5rem",
  background: "#FEEABB",
  borderRadius: "0.5rem",
  input: {
    textAlign: "center",
    color: '#000',
    fontWeight: 600,
  },
  "& fieldset": {
    border: `none !important`,
  },
}));

const PriceBox = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  width: '100%',
}));

const DailyBox = styled(Stack)(({ theme }) => ({
  background: "#FFF06C",
  borderRadius: "0.5rem",
  padding: "0.5rem 1rem",
  minHeight: 40,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

const PayBox = styled(Stack)(({ theme }) => ({
  background: "#FFFFFF",
  borderRadius: "0.5rem",
  padding: "0.5rem 1rem",
  minHeight: 40,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow:
    "inset 0px 4px 0px rgba(255,255,255,0.25), 2px 4px 0px rgba(0,0,0)",
}));
