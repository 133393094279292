import {
  AppBar,
  Box,
  Button,
  Grid,
  Stack,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { t } from "i18next";
import { navigation } from "../context/navigation";
import useLayout from "../context/useLayout";
import FlexBetween from "../../../components/flexbox/FlexBetween";
import { BinaryButton } from "../../../page-sections/homepage/styled";
export default function DashboardFooter() {
  const { active, handleActiveMainMenu } = useLayout();
  return (
    <DashboardFooterRoot >
      <StyledToolBar>
        {/* <Grid container position={'absolute'} px={1}>
          {navigation.map((nav, index) =>
            nav.name === "Home" ? (
              <Grid
                item
                xs={3.2}
                key={index}
                sx={{ position: "relative", overflow: "visible" }}>
                <Button
                  sx={{
                    background: "#FE6003",
                    borderRadius: "0.5rem",
                    height: '100%',
                    overflow: 'hidden'
                  }}
                  onClick={handleActiveMainMenu(nav)}>
                  <Box
                    component={"img"}
                    src={
                      active === nav.name
                        ? "/images/icon-home.png"
                        : nav.iconImage
                    }
                    alt={nav.name}
                    sx={{
                      height: '100%',
                      width: 'auto'
                    }}
                  />
                </Button>
              </Grid>
            ) : (
              <Grid item xs={2.2} key={index}>
                <StyledButton
                  className={active === nav.name ? "active" : ""}
                  onClick={handleActiveMainMenu(nav)}>
                  <Box
                    component={"img"}
                    src={nav.iconImage}
                    alt={nav.name}
                  />
                  <Typography variant="caption">{t(nav.name)}</Typography>
                </StyledButton>
              </Grid>
            ),
          )}
        </Grid> */}
        <FlexBetween sx={{ position: 'absolute', top: 5, left: 0, bottom: 10, right: 5, }}>
          {navigation.map((nav, index) =>
            <Stack key={index} direction={'row'} sx={{ height: '100%', flexGrow: 1, padding: '0.7rem 0.5rem' }}>
              {/* {
                nav.name === "Home" ? <Button
                  sx={{
                    background: "#FE6003",
                    borderRadius: "0.5rem",
                    width: 50,
                    maxHeight: 60
                  }}
                  onClick={handleActiveMainMenu(nav)}>
                  <Box
                    component={"img"}
                    src={
                      active === nav.name
                        ? "/images/icon-home.png"
                        : nav.iconImage
                    }
                    alt={nav.name}
                    sx={{
                      height: '100%',
                      width: 'auto'
                    }}
                  />
                </Button> : <StyledButton
                  className={active === nav.name ? "active" : ""}
                  onClick={handleActiveMainMenu(nav)}>
                  <Box
                    component={"img"}
                    src={nav.iconImage}
                    alt={nav.name}
                  />
                  <Typography variant="caption">{t(nav.name)}</Typography>
                </StyledButton>
              } */}
              {
                nav.name === "Home" ? <StyledButton onClick={handleActiveMainMenu(nav)} sx={{ background: window.location.pathname === '/' ? `#FE6003 !important` : `#0FBAFF !important`, height: '130%', transform: 'translateY(-10%)', borderRadius: '0.7rem' }}>
                  <Box
                    component={"img"}
                    src={
                      active === nav.name
                        ? "/images/icon-home.png"
                        : nav.iconImage
                    }
                    alt={nav.name}
                    sx={{
                      width: '100%',
                      height: 'auto'
                    }}
                  />
                </StyledButton> : <StyledButton onClick={handleActiveMainMenu(nav)} className={window.location.pathname === nav.path ? `active` : ``} >
                  <Box
                    component={"img"}
                    src={nav.iconImage}
                    alt={nav.name}
                    sx={{ width: "35%", height: 'auto' }}
                  />
                  <Typography variant="caption">{t(nav.name)}</Typography>
                </StyledButton>
              }
            </Stack>)
          }
        </FlexBetween>
        <img src="images/menu-bg.png" style={{ width: '100%' }} />
      </StyledToolBar>
    </DashboardFooterRoot>
  );
}

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "0.7rem",
  width: '100%',
  minWidth: 'unset',
  minHeight: 'unset',
  height: '100%',
  flexDirection: 'column',
  padding: 0,
  background: 'none',
  color: '#000',
  '&.active': {
    background: '#08A0DB',
    color: '#fff'
  }
}));

const DashboardFooterRoot = styled(AppBar)(({ theme }) => ({
  position: "fixed",
  maxWidth: 'calc(444px - 1rem)',
  top: 'unset',
  margin: 'auto',
  bottom: 10,
  left: 0,
  background: 'none',
  padding: '0px 1rem',
  boxShadow: "none",
  height: 80,
  justifyContent: 'center',
}));

const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  padding: '0px',
  position: 'relative',
  width: 'fit-content',
  minHeight: 'unset !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important'
}));